import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      {/* mission */}
      <section className="container py-5">
        <div className="row">
          <div className="col-md-8">
            <h1 className="bank-font gold-color">OUR MISSION</h1>
            <p className="minimal-body text-white">
              Words, writings and books can’t give enough credit to how much our
              ancestors{" "}
              <strong>
                (<i>the Assyrians</i>)
              </strong>{" "}
              have contributed to the development of humanity. From agriculture,
              literacy, arts, mathematics to commerce and faith traditions
              they’ve paved the way for our human race to advance and to reach
              the complex level that it has today.
              <br /> <br /> We at{" "}
              <strong>
                <i>TawAlap</i>
              </strong>{" "}
              are proud to have such ancestors and we aim to provide our nation
              with a Brand that represents them in every sense of the word.
              Represents them in their culture, values and faith.{" "}
              <strong>
                <i>Our Mission</i>
              </strong>{" "}
              is to create that brand which represents the Assyrian culture
              and values.
            </p>
          </div>
        </div>
      </section>
      {/* banner */}
      <section className="container-fluid lamassu-gates d-flex align-items-center my-5">
        <div className="container px-0">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <q className="h4 minimal-title text-white">
                I am an Assyrian, and as an Assyrian I am obligated to be
                actively interested in the destiny of the Assyrians, once the
                greatest nation now almost forgotten. I would be an
                arch-chriminal if I failed to assume this obligation.
              </q>
              <p className="h5 minimal-title text-white float-end pt-2">
                — Dr. David B. Perley
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ethic */}
      <section className="container mt-3 pt-3 pb-5">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-8">
            <p className="h2 h1-bank-font">Ethically Sourced Metals</p>
            <p className="minimal-body text-white">
              The metals (Gold & Silver) used to make up our jewelry are of
              highest quality and ethical practices. For us{" "}
              <strong>Quality & Ethics</strong> are the main values when it
              comes to working in the jewelry industry, that is to source metals
              from the best quality and ethical mines which are based in{" "}
              <strong>Canada</strong>.
            </p>
            <p className="minimal-body text-white">
              We put all that along the Assyrian inspired designs to give you
              something valuable & meaningful in a compact form which after all
              is what makes up a jewelry piece.
            </p>
          </div>
        </div>
      </section>
      {/* name */}
      <section className="container mt-3 mt-md-5 py-4">
        <div className="row justify-content-between align-items-start mt-0 mt-md-5">
          <div className="col-md-6 order-1 order-md-0">
            <p className="h2 h1-bank-font">The Brand Name - Taw Alap</p>
            <p className="minimal-body text-white">
              Our name is made of the Aramaic letters Taw & Alap which is quite
              often used in Assyrian words mostly at the end of a word. If
              you’re familiar with the Assyrian language you’d know that Alap is
              the first letter and Taw is the last. We like to use this as a
              guide for us as a Brand and Assyrians as a nation that in order to
              get to the top we must start at the very bottom and build
              our way up — that is <strong>TawAlap</strong>.
            </p>
          </div>
          <div className="col-6 col-md-3 mx-auto order-0 order-md-1">
            <StaticImage
              src="../images/logo.png"
              width={200}
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="tawalap logo"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      {/* CA */}
      <section className="container mt-3 mt-md-5 py-4">
        <div className="row justify-content-center mb-5 pb-5">
          <div className="col-md-12 text-center">
            <StaticImage
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fd/Maple_Leaf.svg/1024px-Maple_Leaf.svg.png"
              width={60}
              quality={100}
              formats={["AUTO", "WEBP"]}
              alt="tawalap logo"
              className="img-fluid opacity-25"
            />
            <p className="h2 h1-bank-font">
              Made in <br /> Toronto, Canada
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default About
